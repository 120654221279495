import { defineStore } from "pinia"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { DateType, Snapshot } from "@evercam/shared/types"

export type CameraOldestLatestSnapshotRecord = {
  latest: Snapshot
  oldest: Snapshot
  maxDate: DateType
}

interface SnapshotState {
  camerasLatestOldestSnapshots: Record<string, CameraOldestLatestSnapshotRecord>
}

export const useSnapshotStore = defineStore("snapshot", {
  state: (): SnapshotState => ({
    camerasLatestOldestSnapshots: {},
  }),
  actions: {
    reset() {
      this.camerasLatestOldestSnapshots = {}
    },
    oldestSnapshotTimestamp(cameraExid: string) {
      return this.camerasLatestOldestSnapshots[cameraExid]?.oldest?.createdAt
    },
    latestSnapshotTimestamp(cameraExid: string) {
      return this.camerasLatestOldestSnapshots[cameraExid]?.latest?.createdAt
    },
    oldestSnapshotImage(cameraExid: string) {
      return this.camerasLatestOldestSnapshots[cameraExid]?.oldest?.data
    },
    latestSnapshotImage(cameraExid: string) {
      return this.camerasLatestOldestSnapshots[cameraExid]?.latest?.data
    },
    async updateLatestAndOldestSnapshots(cameraExid: string) {
      await Promise.all([
        this.updateLatestSnapshot(cameraExid),
        this.updateOldestSnapshot(cameraExid),
      ])
    },
    async updateLatestSnapshot(cameraExid: string) {
      let snapshot: Snapshot
      const maxDate = this.camerasLatestOldestSnapshots[cameraExid]?.maxDate
      try {
        if (maxDate) {
          const { snapshots } = await EvercamApi.recordings.nearest(
            cameraExid,
            maxDate
          )
          snapshot = snapshots[0]
        } else {
          snapshot = await EvercamApi.recordings.latest(cameraExid)
        }
      } catch (e) {
        console.log(e)
      }
      this.camerasLatestOldestSnapshots[cameraExid] = {
        ...this.camerasLatestOldestSnapshots[cameraExid],
        latest: {
          data: snapshot?.data || "unavailable.jpg",
          createdAt: snapshot?.createdAt || new Date().toISOString(),
        },
      }
    },
    async updateOldestSnapshot(cameraExid: string) {
      let response: Snapshot

      try {
        response = await EvercamApi.recordings.oldest(cameraExid)
      } catch (e) {
        console.log(e)
      }
      this.camerasLatestOldestSnapshots[cameraExid] = {
        ...this.camerasLatestOldestSnapshots[cameraExid],
        oldest: {
          data: response?.data || "unavailable.jpg",
          createdAt: response?.createdAt || new Date().toISOString(),
        },
      }
    },
  },
})
