import { defineStore } from "pinia"
import { EvercamGoFeatures } from "@evercam/shared/types"

interface MediaHubState {
  dialog: boolean
  feature?: EvercamGoFeatures
}

export const useGoStore = defineStore("go", {
  state: (): MediaHubState => ({
    dialog: false,
    feature: null,
  }),
  actions: {
    openDialog(feature: EvercamGoFeatures) {
      this.feature = feature
      this.dialog = true
    },
    closeDialog() {
      this.feature = null
      this.dialog = false
    },
  },
})
