import { defineStore } from "pinia"
import { VideoWallApi } from "@evercam/shared/api/videoWallApi"
import { VideoWallPresetItemConfig } from "@evercam/shared/types"
import { useProjectStore } from "@evercam/dashboard/stores/project"
import { useAccountStore } from "@evercam/dashboard/stores/account"
import { useNuxtApp } from "#app"

interface VideoWallState {
  preset: Array<VideoWallPresetItemConfig> | null
  isLoading: boolean
}

export const useVideoWallStore = defineStore({
  id: "videoWall",
  state: (): VideoWallState => ({
    preset: null,
    isLoading: false,
  }),
  actions: {
    async saveVideoWallConfiguration(
      configuration: VideoWallPresetItemConfig[]
    ) {
      try {
        const preset = {
          configuration: configuration,
          name: "preset-1",
        }
        await VideoWallApi.presets.saveProjectPreset(
          useAccountStore().email,
          useProjectStore().selectedProjectExid,
          preset
        )
        this.fetchVideoWallConfiguration()
        useNuxtApp().nuxt2Context.$notifications.success(
          useNuxtApp().vue2App.$i18n.t(
            "content.video_wall.save_configuration_success"
          )
        )
      } catch (e) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: useNuxtApp().vue2App.$i18n.t(
            "content.video_wall.save_configuration_failed"
          ),
          error: e,
        })
      }
    },
    async saveGlobalVideoWallConfiguration(
      configuration: VideoWallPresetItemConfig[]
    ) {
      try {
        const preset = {
          configuration: configuration,
          name: "preset-1",
        }
        await VideoWallApi.presets.saveGlobalPreset(
          useAccountStore().email,
          preset
        )
        this.fetchVideoWallConfiguration(true)
        useNuxtApp().nuxt2Context.$notifications.success(
          useNuxtApp().vue2App.$i18n.t(
            "content.video_wall.save_configuration_success"
          )
        )
      } catch (e) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: useNuxtApp().vue2App.$i18n.t(
            "content.video_wall.save_configuration_failed"
          ),
          error: e,
        })
      }
    },
    async fetchVideoWallConfiguration(global = false) {
      this.isLoading = true
      try {
        if (global) {
          const preset = await VideoWallApi.presets.getGlobalPreset(
            useAccountStore().email
          )
          if (!preset) {
            return
          }
          this.preset = preset.configuration
        } else {
          const preset = await VideoWallApi.presets.getProjectPresets(
            useAccountStore().email,
            useProjectStore().selectedProjectExid
          )
          if (!preset || !preset["preset1"]) {
            return
          }
          this.preset = preset["preset1"].configuration
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },
  },
})
