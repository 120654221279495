import { defineStore } from "pinia"
import { WidgetFull } from "@evercam/api/types"
import { EvercamApi } from "@evercam/shared/api/evercamApi"

interface MediaHubState {
  currentWidget?: WidgetFull
}

export const useWidgetStore = defineStore("widget", {
  state: (): MediaHubState => ({
    currentWidget: null,
  }),
  actions: {
    async fetchWidget() {
      this.currentWidget = await EvercamApi.widgets.getByKeys()
    },
    async setSessionSettings(params: any) {
      this.currentWidget = await EvercamApi.widgets.setSessionSettings({
        sessionSettings: params,
      })
    },
  },
})
